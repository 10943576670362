import { gql } from '@apollo/client';
import {
  ReservationPaymentFragment,
  RoomFragment,
  RoomTypeFragment,
} from 'graphql/fragments';
import { ReservationFragmentWithoutRoom } from 'graphql/reservations';
import { EmployeeFragment } from 'graphql/user';

export const RoomDeviceFragment = gql`
  fragment RoomDeviceFragment on RoomDevice {
    id
    name
    type
    state
    power
  }
`;

export const getMyAccommodationsMaidQuery = gql`
  query getMyAccommodations($useStartAt: Date, $useExpireAt: Date) {
    getMyAccommodations {
      id
      name
      thumbnail
      isActive
      todayReservationCount: todayReservationCountByType(
        useStartAt: $useStartAt
        useExpireAt: $useExpireAt
      ) {
        type
        count
      }
      activeReservationCount
      state
      kiosks {
        id
        state
      }
    }
  }
`;

export const getMyAccommodationsQuery = gql`
  query getMyAccommodations($useStartAt: Date, $useExpireAt: Date) {
    getMyAccommodations {
      id
      name
      thumbnail
      salesToday
      isActive
      todayReservationCount: todayReservationCountByType(
        useStartAt: $useStartAt
        useExpireAt: $useExpireAt
      ) {
        type
        count
      }
      activeReservationCount
      state
      kiosks {
        id
        state
      }
    }
    getMySalesReport {
      typeAggregates {
        type
        amount
      }
      dateAggregates {
        date
        amount
      }
    }
  }
`;

export const getSingleRoomQuery = gql`
  query getSingleRoom($id: ID!) {
    getSingleRoom(id: $id) {
      ...RoomFragment
      roomDevices {
        id
        name
        type
        power
        state
      }
      currentReservation {
        ...ReservationFragmentWithoutRoom
      }
      upcomingReservation {
        ...ReservationFragmentWithoutRoom
      }
    }
  }

  ${RoomFragment}
  ${ReservationFragmentWithoutRoom}
`;

export const getOTASettingsQuery = gql`
  query getSingleAccommodation($id: ID!, $ota: OTA!) {
    getSingleAccommodation(id: $id) {
      id
      otaSetting(ota: $ota) {
        id
        type
        value
      }
      roomTypes {
        id
        name
        otaSetting(ota: $ota) {
          id
          type
          value
        }
      }
    }
  }
`;

export const getTodayReservationCountQuery = gql`
  query GetSingleAccommodation(
    $id: ID!
    $useStartAt: Date
    $useExpireAt: Date
  ) {
    getSingleAccommodation(id: $id) {
      todayReservationCount: todayReservationCountByType(
        useStartAt: $useStartAt
        useExpireAt: $useExpireAt
      ) {
        type
        count
      }
    }
  }
`;

export const getSingleAccommodationQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      name
      isActive
      defaultCheckIn
      defaultCheckOut
      kiosks {
        id
        name
        type
        state
        connectionState
        rooms {
          ...RoomFragment
          currentReservation {
            ...ReservationFragmentWithoutRoom
            payment {
              ...ReservationPaymentFragment
            }
          }
          recentReservation {
            ...ReservationFragmentWithoutRoom
            payment {
              ...ReservationPaymentFragment
            }
          }
          upcomingReservation {
            ...ReservationFragmentWithoutRoom
            payment {
              ...ReservationPaymentFragment
            }
          }
          roomType {
            id
            alias
            name
            priority
            checkIn
            checkOut
            description
            currentPrice {
              priceType
              cardAmount
              cashAmount
              defaultRentTime
              extendedRentTime
              rentExpireAt
              lateCheckoutLimit
            }
          }
          roomDevices {
            id
            name
            type
            power
            state
          }
        }
      }
    }
  }
  ${ReservationFragmentWithoutRoom}
  ${RoomFragment}
  ${ReservationPaymentFragment}
`;

export const getAccommodationKiosksOptionsQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      kiosks {
        id
        name
        options {
          useCheckVehicle
          useCheckPassport
          useRentSale
          useLodgeSale
          useBlockMinor
          useBlockMinorInReservation
          useStandaloneCCU
          collectContactStrategy
          collectSleepsStrategy
          blockMinorAgeBase
          useAgreementTerms
          blockMinorTimeRange
          idleMusic
          theme
          firstUpperCameraDeviceId
          secondUpperCameraDeviceId
          qrCameraDeviceId
          ccuComPort
          ioBoardComPort
          cardReaderComPort
          thermalPrinterComPort
          cashInjectorPort
          icCardReaderNumber
          firstCashDispensorPort
          secondCashDispensorPort
          firstCashDispensorAmount
          secondCashDispensorAmount
          rfidWriterComPort
          autoRebootInterval
          autoRebootTime
          printReceiptStrategy
          kakaoAPIKey
          ccuType
          useDetectGhostClean
          usePreventAutoClean
          disableAircon
          maxKeyCardAmount
          useDisableCarRequest
          logDeviceNotFoundIgnore
          version
          ccuVersion
        }
      }
    }
  }
`;

export const updateRoomStateQuery = gql`
  query getSingleRoom($id: ID!) {
    getSingleRoom(id: $id) {
      ...RoomFragment
      currentReservation {
        ...ReservationFragmentWithoutRoom
        payment {
          ...ReservationPaymentFragment
        }
      }
      upcomingReservation {
        ...ReservationFragmentWithoutRoom
        payment {
          ...ReservationPaymentFragment
        }
      }
      recentReservation {
        ...ReservationFragmentWithoutRoom
        payment {
          ...ReservationPaymentFragment
        }
      }
    }
  }
  ${RoomFragment}
  ${ReservationFragmentWithoutRoom}
  ${ReservationPaymentFragment}
`;

export const getAccommodationMetaInfoMaidQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      name
      contact
      csContact
      defaultCheckIn
      defaultCheckOut
      address1
      address2
      thumbnail
      roomTypes {
        id
        name
        checkIn
        checkOut
        description
        quantity
        currentPrice {
          priceType
          cardAmount
          cashAmount
          defaultRentTime
          extendedRentTime
          rentExpireAt
          lateCheckoutLimit
        }
      }
      employees {
        ...EmployeeFragment
      }
      themes {
        id
        name
      }
      options {
        key
        value
      }
    }
  }
  ${EmployeeFragment}
`;

export const getAccommodationMetaInfoQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      name
      contact
      csContact
      registererName
      registrationNumber
      defaultCheckIn
      defaultCheckOut
      address1
      address2
      thumbnail
      roomTypes {
        id
        name
        checkIn
        checkOut
        description
        quantity
        currentPrice {
          priceType
          cardAmount
          cashAmount
          defaultRentTime
          extendedRentTime
          rentExpireAt
          lateCheckoutLimit
        }
      }
      employees {
        ...EmployeeFragment
      }
      themes {
        id
        name
      }
      options {
        key
        value
      }
    }
  }
  ${EmployeeFragment}
`;

export const getAccommodationRoomsQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      rooms {
        id
        name
        thumbnail
        saleState
        calculatedState
        number
        roomType {
          id
          alias
          name
          priority
        }
      }
    }
  }
`;

export const getAccommodationRoomTypesQuery = gql`
  query GetSingleAccommodation($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      roomTypes {
        ...RoomTypeFragment
      }
    }
  }
  ${RoomTypeFragment}
`;

export const accommodationSalesQuery = gql`
  query AccommodationSales($id: ID!, $reservationType: String) {
    getSingleAccommodation(id: $id) {
      id
      salesToday(reservationType: $reservationType)
      cardSalesToday(reservationType: $reservationType)
      cashSalesToday(reservationType: $reservationType)
    }
  }
`;

export const createAccommodationMutation = gql`
  mutation CreateAccommodation($input: CreateAccommodationInput!) {
    createAccommodation(input: $input) {
      id
    }
  }
`;

export const updateAccommodationMutation = gql`
  mutation updateAccommodation($input: UpdateAccommodationInput!) {
    updateAccommodation(input: $input) {
      id
      defaultCheckIn
      defaultCheckOut
      csContact
    }
  }
`;

export const createThemeMutation = gql`
  mutation createTheme($name: String!, $accommodationId: ID!) {
    createTheme(name: $name, accommodationId: $accommodationId) {
      id
    }
  }
`;

export const updateRoomMutation = gql`
  mutation updateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      ...RoomFragment
      roomDevices {
        id
        name
        type
        power
        state
      }
    }
  }
  ${RoomFragment}
`;

export const createRoomTypeMutation = gql`
  mutation createRoomType($input: CreateRoomTypeInput!) {
    createRoomType(input: $input) {
      id
      name
      checkIn
      checkOut
      description
      rooms {
        ...RoomFragment
      }
    }
  }
  ${RoomFragment}
`;

export const updateRoomsMutation = gql`
  mutation updateRooms($input: [UpdateRoomInput!]!) {
    updateRooms(input: $input) {
      ...RoomFragment
    }
  }
  ${RoomFragment}
`;

export const setRoomStateMutation = gql`
  mutation setRoomState($id: ID!, $state: RoomState!) {
    setRoomState(id: $id, state: $state) {
      ...RoomFragment
    }
  }
  ${RoomFragment}
`;

export const setKioskOptionsMutation = gql`
  mutation setKioskOptions($inputs: [KioskOptionInput]!, $kioskId: ID!) {
    setKioskOptions(inputs: $inputs, kioskId: $kioskId) {
      key
      value
    }
  }
`;

export const uploadAccommodationImageMutation = gql`
  mutation uploadAccommodationImage($accommodationId: ID!, $file: Upload!) {
    uploadAccommodationImage(accommodationId: $accommodationId, file: $file) {
      id
      thumbnail
    }
  }
`;

export const uploadRoomImageMutation = gql`
  mutation uploadRoomImage($roomId: ID!, $file: Upload!) {
    uploadRoomImage(roomId: $roomId, file: $file) {
      id
      thumbnail
    }
  }
`;

export const uploadRoomTypeImageMutation = gql`
  mutation uploadRoomTypeImage($roomTypeId: ID!, $file: Upload!) {
    uploadRoomTypeImage(roomTypeId: $roomTypeId, file: $file) {
      id
      thumbnail
    }
  }
`;

export const getCurrentReservationForRoomQuery = gql`
  query getSingleRoom($id: ID!) {
    getSingleRoom(id: $id) {
      id
      currentReservation
    }
  }
`;

export const deleteRoomTypeMutation = gql`
  mutation deleteRoomType($id: ID!) {
    deleteRoomType(id: $id) {
      result
    }
  }
`;

export const updateRoomTypeMutation = gql`
  mutation updateRoomType($input: UpdateRoomTypeInput!) {
    updateRoomType(input: $input) {
      ...RoomTypeFragment
    }
  }
  ${RoomTypeFragment}
`;

export const addOTASetting = gql`
  mutation addOTASetting($input: AddOTASettingInput!) {
    addOTASetting(input: $input) {
      id
      accommodationId
      relatedId
      type
      value
    }
  }
`;

export const modifyOTASetting = gql`
  mutation modifyOTASetting($input: ModifyOTASettingInput!) {
    modifyOTASetting(input: $input) {
      id
      accommodationId
      relatedId
      type
      value
    }
  }
`;

export const publishAccommodationNotificaitonMutation = gql`
  mutation publishAccommodationNotification(
    $input: AddAccommodationNotificationInput!
  ) {
    publishAccommodationNotification(input: $input) {
      id
    }
  }
`;

export const markRoomCarResponseMutation = gql`
  mutation markRoomCarResponse($kioskId: ID!, $rcuId: Int!) {
    markRoomCarResponse(kioskId: $kioskId, rcuId: $rcuId)
  }
`;

export const sendKioskControlPacketMutation = gql`
  mutation sendKioskControlPacket($kioskId: ID!, $data: String!) {
    sendKioskControlPacket(kioskId: $kioskId, data: $data)
  }
`;

export const sendRcuControlPacketMutation = gql`
  mutation sendRcuControlPacket($kioskId: ID!, $data: String!) {
    sendRcuControlPacket(kioskId: $kioskId, data: $data)
  }
`;

export const updateRoomDeviceMutation = gql`
  mutation updateRoomDevice($input: DeviceInput!, $id: ID!) {
    updateRoomDevice(input: $input, id: $id) {
      ...RoomDeviceFragment
    }
  }
  ${RoomDeviceFragment}
`;

const AudioOptionFragment = gql`
  fragment AudioOptionFragment on AudioOption {
    id
    accommodationId
    key
    type
    time
    templateId
    groupId
    label
  }
`;

export const createAccommodationAudioOptionMutation = gql`
  mutation createAccommodationAudioOption($input: CreateAudioOptionInput!) {
    createAccommodationAudioOption(input: $input) {
      ...AudioOptionFragment
    }
  }
  ${AudioOptionFragment}
`;

export const updateAccommodationAudioOptionMutation = gql`
  mutation updateAccommodationAudioOption($input: UpdateAudioOptionInput!) {
    updateAccommodationAudioOption(input: $input) {
      ...AudioOptionFragment
    }
  }
  ${AudioOptionFragment}
`;

export const deleteAccommodationAudioOptionMutation = gql`
  mutation delelteAccommodationAudioOption($id: ID!) {
    delelteAccommodationAudioOption(id: $id)
  }
`;

export const getAccommodationAudioOptionsQuery = gql`
  query getAccommodationAudioOptions($id: ID!, $key: String) {
    getAccommodationAudioOptions(id: $id, key: $key) {
      ...AudioOptionFragment
    }
  }
  ${AudioOptionFragment}
`;

export const getBriefAccommodationByIdQuery = gql`
  query getBriefAccommodationById($id: ID!) {
    getBriefAccommodationById(id: $id) {
      id
      name
      thumbnail
      isActive
    }
  }
`;
