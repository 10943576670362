import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { LayoutFlexCenterAligned } from './Layouts';

const Wrapper = styled(LayoutFlexCenterAligned)`
  width: 100%;
  height: 100%;
`;

const Loading = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default Loading;
